<template>
  <el-dialog
      :title="'预览'"
      :visible.sync="previewVisible"
      width="fit-content"
      :close-on-click-modal="false"
      @closed="close"
  >
    <div style="width: 375px; height: 667px; overflow-x: hidden; overflow-y: auto;display: flex; flex-direction: column; align-items: center" @scroll="onScroll" @click="onScroll" v-if="previewVisible">
      <div style="width: 347px; height: fit-content; display: flex; flex-direction: column; ">
        <div style="width: 347px; display: flex; flex-direction: column">
          <!--顶部基本信息-->
          <div style="width: 347px; height: 102px; background: linear-gradient(to right, #FFDAD1, #FFF4F2); border: 1px solid #FFBFAC; border-radius: 4px; display: flex; flex-direction: row; align-items: center; box-sizing: border-box">
            <div class="avatar_bg" style="width: 84px; height: 84px; box-sizing: border-box; margin-left: 12px; padding: 5px 10px 15px 10px">
              <div style="width: 64px; height: 64px; border-radius: 32px; background-color: #BD8989; display: flex; justify-content: center; align-items: center">
                <avatar style="height: 58px; width: 58px;" :avatar="info_data.avatar"></avatar>
              </div>
            </div>

            <div style="margin-left: 13px; width: 220px; display: flex; flex-direction: column">
              <div style="color: #000000; font-size: 14px; font-weight: 600">
                {{ info_data.name }}
              </div>
              <div style="display: flex; flex-direction: row; justify-content: space-between; margin-top: 6px; color: #333333">
                <div style="display: flex; flex-direction: row; font-size: 13px">
                  <div style="font-weight: 600">
                    门派编号：
                  </div>
                  <div>
                    {{info_data.id}}
                  </div>
                </div>
                <div style="display: flex; flex-direction: row; font-size: 13px" v-if="info_data.city && info_data.city.length > 0">
                  <i class="el-icon-location" style="font-size: 18px"></i>
                  <div style="margin-left: 8px">{{ info_data.city }}</div>
                </div>
              </div>
              <div style="font-size: 10px; color: #666666; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical; margin-top: 7px">
                {{`宣言：${info_data.declaration&&info_data.declaration.length > 0?info_data.declaration:'暂无'}`}}
              </div>
            </div>
          </div>
        </div>

        <!--榜单信息-->
        <div style="display: flex; flex-direction: column; margin-top: 15px" v-if="rank_info">
          <div style="display: flex; flex-direction: row; align-items: center">
            <div style="width: 5px; height: 13px; background-color: #9B5555; border-radius: 3px"></div>
            <div style="color: #9B5555; font-size: 14px; font-weight: 600; margin-left: 5px">积分详情</div>
          </div>
          <div style="background-color: #BD8989; border-radius: 4px; margin-top: 10px; height: 28px; width: 100%">
            <div style="display: flex; flex-direction: column; width: fit-content; height: 100%; margin-left: 10px; justify-content: center">
              <div style="color: #FFFFFF; font-size: 12px;">{{season_name}}</div>
              <div style="width: 100%; height: 2px; border-radius: 1px; background-color: #FFFFFF"></div>
            </div>
          </div>
          <div style="margin-top: 10px; background-color: rgba(189, 137, 137, 0.2); height: 28px; width: 100%; display: flex; flex-direction: row">
            <div style="padding-left: 10px; width: 45%; box-sizing: border-box; color: #9B5555; font-size: 13px; font-weight: 600; display: flex; flex-direction: row; align-items: center">
              活动类型
            </div>
            <div style="width: 45%; box-sizing: border-box; color: #9B5555; font-size: 13px; font-weight: 600; display: flex; flex-direction: row; align-items: center">
              活动详情
            </div>
            <div style="width: 10%; box-sizing: border-box; color: #9B5555; font-size: 13px; font-weight: 600; display: flex; flex-direction: row; align-items: center">
              积分
            </div>
          </div>
          <div v-for="(item, index) in rank_info.items" :key="`items_${index}`" :style="[{ '': index % 2 == 0 ? 'transparent' : 'rgba(189, 137, 137, 0.2)' }]" style="height: 28px; width: 100%; color: #333333; font-size: 11px; display: flex; flex-direction: row">
            <div style="padding-left: 10px; width: 45%; box-sizing: border-box; display: flex; flex-direction: row; align-items: center">
              {{item.name}}
            </div>
            <div style="width: 45%; box-sizing: border-box; display: flex; flex-direction: row; align-items: center">
              <div v-if="!item.merge">
                {{item.sub_name}}
              </div>
              <div v-else>
                <el-popover
                    placement="bottom"
                    width="fit-content"
                    trigger="manual"
                    v-model="item.visible">
                  <div v-for="(sub_item, i) in item.items" :key="`sub_items_${i}`" :style="[{'border-bottom': i < item.items.length - 1 ? '1px solid #CCCCCC' : 'none'}]" style="color: #333333; font-size: 12px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; box-sizing: border-box; height: 30px; width: 143px; display: flex; justify-content: center;align-items: center;text-align: center">
                    {{sub_item.name}}
                  </div>
                  <div slot="reference" style="color: #9B5555; font-size: 11px;cursor: pointer" @click.stop="showSubItems(item)">
                    {{`${getRankSubname(item)}>>`}}
                  </div>
                </el-popover>
              </div>
            </div>
            <div style="width: 10%; box-sizing: border-box; display: flex; flex-direction: row; align-items: center">
              {{item.value}}
            </div>
          </div>

          <div style="background-color: #F2E7E7; height: 28px; width: 100%; color: #000000; font-size: 11px; font-weight: 600; display: flex; flex-direction: row; ">
            <div style="padding-left: 10px; width: 90%; box-sizing: border-box; display: flex; flex-direction: row; align-items: center">
              赛季总积分
            </div>
            <div style="width: 10%; display: flex; flex-direction: row; align-items: center">
              {{rank_info.value}}
            </div>
          </div>
        </div>

        <!--门派介绍-->
        <div style="display: flex; flex-direction: column; margin-top: 20px; width: 100%" class="ql-snow-review">
          <div style="display: flex; flex-direction: row; align-items: center">
            <div style="width: 5px; height: 13px; background-color: #9B5555; border-radius: 3px"></div>
            <div style="color: #9B5555; font-size: 14px; font-weight: 600; margin-left: 5px">门派介绍</div>
          </div>
          <div class="ql-editor-review" style="margin-top: 10px; background-color: #F4F4F4; border-radius: 4px; padding: 12px; box-sizing: border-box; font-size: 12px; height: fit-content; overflow: hidden; line-height: unset" v-html="info_data.desc"></div>
        </div>

        <!--成员介绍-->
        <div style="display: flex; flex-direction: column; margin-top: 20px; width: 100%" v-if="info_data.members && info_data.members.length">
          <div style="display: flex; flex-direction: row; align-items: center">
            <div style="width: 5px; height: 13px; background-color: #9B5555; border-radius: 3px"></div>
            <div style="color: #9B5555; font-size: 14px; font-weight: 600; margin-left: 5px">成员介绍</div>
          </div>
          <div v-for="(item, index) in info_data.members" :key="`member_${index}`" style="margin-top: 10px; width: 347px; box-sizing: border-box; height: 100px; display: flex; flex-direction: row; align-items: center; background-color: #FFF3F3; border-radius: 4px; border: 1px solid #FFBFAC">
            <div style="display: flex; flex-direction: column; align-items: center; margin-left: 15px">
              <div style="width: 64px; height: 64px; background-color: #BD8989; border-radius: 32px; display: flex; justify-content: center; align-items: center">
                <avatar style="width: 58px; height: 58px; border-radius: 29px" :avatar="item.avatar"/>
              </div>
              <div style="margin-top: 4px; color: #9B5555; font-size: 12px; font-weight: 600; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; width: 64px; text-align: center">{{item.name}}</div>
            </div>
            <div style="margin-left: 22px; width: 230px; display: flex; flex-direction: column; color: #000000">
              <div style="display: flex; flex-direction: row; justify-content: space-between; font-size: 11px">
                <div style="display: flex; flex-direction: row;">
                  <div style="font-weight: 600">
                    角色编号：
                  </div>
                  <div>
                    {{item.uid}}
                  </div>
                </div>
                <div style="display: flex; flex-direction: row;">
                  <div style="font-weight: 600">
                    职务：
                  </div>
                  <div style="min-width: 44px">{{ item.job }}</div>
                </div>
              </div>
              <div style="font-size: 11px; color: #666666; margin-top: 10px; height: 39px; width: 230px">
                <div style="display: -webkit-box; overflow: hidden; -webkit-line-clamp: 3; -webkit-box-orient: vertical; width: 230px">
                  {{item.desc}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--桌游吧-->
        <div style="display: flex; flex-direction: column; margin-top: 20px; width: 100%" v-if="info_data.board_game_bar && info_data.board_game_bar.has" class="ql-snow-review">
          <div style="display: flex; flex-direction: row; align-items: center">
            <div style="width: 5px; height: 13px; background-color: #9B5555; border-radius: 3px"></div>
            <div style="color: #9B5555; font-size: 14px; font-weight: 600; margin-left: 5px">合作桌游吧</div>
          </div>
          <div style="margin-top: 10px; font-size: 12px; height: fit-content; overflow: hidden; line-height: unset" v-html="info_data.board_game_bar.desc" class="ql-editor-review"></div>

          <div v-if="info_data.board_game_bar.gis && info_data.board_game_bar.gis.addr && info_data.board_game_bar.gis.addr.length" style="margin-top: 16px">
            <div style="color: #000000; font-weight: 600; font-size: 12px">·桌游吧地图</div>
            <div style="display: flex; flex-direction: row; margin-top: 5px">
              <div style="color: #333333; font-size: 12px">{{info_data.board_game_bar.gis.addr}}</div>
              <img style="width: 18px; height: 18px" src="../assets/armory_detail_navigation.png"/>
            </div>
            <div style="margin-top: 10px">
<!--              <iframe style="border: none;" :src="`https://apis.map.qq.com/tools/poimarker?type=0&tonav=0&marker=coord:${info_data.board_game_bar.gis.lat},${info_data.board_game_bar.gis.lng};title:${encodeURIComponent(info_data.board_game_bar.gis.addr)}&key=UU2BZ-B6RKG-QCTQW-IA6XO-5NHSH-Q6BYM&referer=myapp`" width="347" height="200"></iframe>-->
              <img style="width:347px; height:200px" src="../assets/armory_sect_gis_placeholder.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import avatar from "../components/avatar";
export default {
  name: "preview",
  emits: ['closed'],
  components: {
    avatar
  },
  props: {
    info: {
      type: Object
    },
    ref_id: {
      type: String
    },
    season_id: {
      type: String
    },
    season_name: {
      type: String
    }
  },
  data() {
    return {
      type: 'sect',
      previewVisible: false,
      rank_info: false,
      info_data: false
    }
  },
  watch: {
    info: {
      handler:function(val){
        this.info_data = val;
      },
      immediate:true
    }
  },
  methods: {
    onScroll() {
      if (this.rank_info && this.rank_info.items) {
        this.rank_info.items.forEach(r => {
          r.visible = false;
        });
      }
    },
    showSubItems(item) {
      let v = item.visible;
      this.onScroll();
      item.visible = !v;
    },
    close() {
      this.$emit('closed');
    },
    getRankSubname(item) {
      return `参与${item.items.length}场赛事活动`
    },
    async loadData() {
      if (!this.info) {
        let {data, errorCode} = await this.$http.armory.detail(this.ref_id, this.type);
        if (errorCode != '0000' || !data) {
          this.close();
          return;
        }

        this.info_data = data;
      } else {
        this.info_data = this.info;
      }

      if (this.season_id && this.season_id.length) {
        let {data, errorCode} = await this.$http.armory.detailRank(this.season_id, this.ref_id, this.type);
        if (errorCode != '0000') {
          this.close();
          return;
        }

        this.rank_info = data;
        if (this.rank_info && this.rank_info.items) {
          this.rank_info.items = this.rank_info.items.map(r => {
            return {
              ...r,
              visible: false
            }
          });
        }
      }

      this.previewVisible = true;
    }
  },
  created() {
    this.loadData();
  }
}
</script>

<style lang="css" src="../../activities/assets/activity.css"></style>
<style scoped>
  .avatar_bg {
    background: url('../assets/armory_rank_icon_bg.png');
    background-size: 84px 84px;
    background-repeat: no-repeat;
  }
</style>

<style>
.el-dialog__wrapper {
  overflow: hidden!important;
}
</style>
