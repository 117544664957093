<template>
  <div style="height: 100%; width: 100%;">
    <img ref="" style="height: 100%; width: 100%; border-radius: 50%" @error="avatarError" :src="avatar && avatar.length ? avatar : require('../assets/armory_portrait.jpg')"/>
  </div>
</template>

<script>
export default {
  name: "avatar",
  props: {
    avatar: {
      type: String
    }
  },
  methods: {
    avatarError(e) {
      e.target.src = require('../assets/armory_portrait.jpg');
    }
  }
}
</script>

<style scoped>

</style>
